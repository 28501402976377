import React from 'react'
import { Helmet } from "react-helmet"
import { API } from "@stoplight/elements"
import DocsHead from "../../../components/DocsHead"

const Reference = ({ location }) => {
    const [isWindow, setIsWindow] = React.useState(false);

    React.useEffect(() => {
        setIsWindow(true);
        return () => setIsWindow(false);
    });

    // we check if the window is set cuz the api render component can't figure out how wide the viewport is on the server
    if(!isWindow) {
        return <div></div>;
    }

    return (
        <div>
            <Helmet>
                <title>Firstparty - API Reference</title>
                
            </Helmet>
            <DocsHead fluid={true} fixed={true} location={location} />
            <nav className="bg-dark fixed-top fixed-top-sub">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <span className="text-white">
                                        Docs
                                    </span>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="text-white">
                                        API
                                    </span>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="text-white">
                                        Management API Reference
                                    </span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>

            <div style={{position: 'absolute', width: "100%", height: "100%", paddingTop: '134px', overflow: "scroll"}}>
                <section style={{height: '100%'}}>
                    <div className="documentation-container">
                        <div>
                            <API
                                apiDescriptionUrl="https://firstparty.github.io/docs/specs//openapi.yaml"
                                router={typeof window === 'undefined' ? 'static' : 'history'}
                                basePath="/docs/api/reference/"
                                hideSchemas={true}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Reference
